<template>
	<div class="lc-decorate el-content">
		<a-tabs v-model:activeKey="active">
			<template #tabBarExtraContent>
				<a-button 
					type="primary" 
					v-if="active == 1"
					v-has="{action:'wxapp_land_custom_decorate_add',plat:0}"
					@click="showDecorateEdit(0)">
					<i class="ri-add-line"></i>添加装饰
				</a-button>
				<a-button 
					type="primary" 
					v-if="active == 2"
					v-has="{action:'wxapp_land_custom_decorate_type_add',plat:0}"
					@click="showTypeEdit(0)"  >
					<i class="ri-add-line"></i>添加分类
				</a-button>
			</template>
		    <a-tab-pane key="1" tab="装饰列表">
				<a-table :pagination="false" row-key="id" :data-source="info.list" :columns="[
					{title:'ID',dataIndex:'id'},
					{title:'装饰名称',dataIndex:'name'},
					{title:'规格',dataIndex:'spec'},
					{title:'分类',dataIndex:'cates.name'},
					{title:'价格',dataIndex:'price',slots:{customRender:'price'}},
					{title:'状态',dataIndex:'is_put_away',slots:{customRender:'is_put_away'}},
					{title:'操作',dataIndex:'action',slots:{customRender:'action'}},
				]" >
					<template #price="{record}">
						<span>{{record.price}}元/㎡</span>
					</template>
					<template #is_put_away="{record}">
						<a-tag :color="record.is_put_away == 1 ?'#00CC66':'#999'">
							{{ record.is_put_away == 1 ?'上架':'已下架'}}
						</a-tag>
					</template>
					<template #action="{record}">
						<a-space>
							<kd-button type="primary" 
								title="编辑" 
								icon="ri-edit-line" 
								v-has="{action:'wxapp_land_custom_decorate_add'}" 
								@click="showDecorateEdit(record)">
							</kd-button>
							<kd-button type="danger" 
								title="删除" 
								icon="ri-delete-bin-5-line" 
								v-has="{action:'wxapp_land_custom_decorate_del'}"
								@click="handleDelete(record.id,57,'确认删除装饰信息吗？')">
							</kd-button>
						</a-space>
					</template>
				</a-table>
				<div class="pager">
					<a-pagination
						show-size-changer
						:default-current="info.page"
						:total="info.count"
						@showSizeChange="(p,e)=>{getDecorateList(info.page,e)}"
						@change="(e)=>{getDecorateList(e,info.limit)}"
					/>
				</div>
			</a-tab-pane>
		    <a-tab-pane key="2" tab="装饰分类">
				<a-table :pagination="false" row-key="id" :data-source="type" :columns="[
					{title:'ID',dataIndex:'id'},
					{title:'分类名称',dataIndex:'name'},
					{title:'排序',dataIndex:'rank'},
					{title:'创建时间',dataIndex:'create_time'},
					{title:'操作',dataIndex:'action',slots:{customRender:'action'}},
				]" >
					<template #action="{record}">
						<a-space>
							<kd-button type="primary" 
								title="编辑" 
								icon="ri-edit-line" 
								v-has="{action:'wxapp_land_custom_decorate_type_add'}"  
								@click="showTypeEdit(record)">
							</kd-button>
							<kd-button type="danger" 
								title="删除" 
								icon="ri-delete-bin-5-line" 
								v-has="{action:'wxapp_land_custom_decorate_type_del'}" 
								@click="handleDelete(record.id,59,'确认删除该装饰分类信息吗?')">
							 </kd-button>
						</a-space>
					</template>
				</a-table>
			</a-tab-pane>
		</a-tabs>
		<a-modal v-model:visible="show.edit" title="添加分类" @ok="saveDecorateType" width="600px" @cancel="show.edit = false">
			<a-form :label-col="{ span: 4 }" :wrapper-col="{ span: 16 }">
				<a-form-item label="分类名称">
					<a-input v-model:value="form.name"></a-input>
				</a-form-item>
				<a-form-item label="排序">
					<a-input v-model:value="form.rank"></a-input>
				</a-form-item>
			</a-form>
		</a-modal>
		
		<a-modal v-model:visible="show.decorate" title="添加装饰" @ok="saveDecorate" width="600px" @cancel="show.decorate = false">
			<a-form :label-col="{ span: 4 }" :wrapper-col="{ span: 16 }">
				<a-form-item label="装饰分类" name="cate">
					<a-select v-model:value="decorateForm.cate">
						<a-select-option v-for="(item,index) in type" :key="index" :value="item.id">{{item.name}}</a-select-option>
					</a-select>
				</a-form-item>
				<a-form-item label="装饰名称" name="name">
					<a-input v-model:value="decorateForm.name"></a-input>
				</a-form-item>
				<a-form-item label="装饰规格" name="spec">
					<a-input v-model:value="decorateForm.spec"></a-input>
				</a-form-item>
				<a-form-item label="计价方式" name="price">
					<a-input v-model:value="decorateForm.price" placeholder="如:20cm高" addon-after="元/㎡">
					</a-input>
				</a-form-item>
				<a-form-item label="示例图(96*96)" name="images">
					<kd-img-select :src="decorateForm.images" @success="(url)=>{ decorateForm.images = url}"></kd-img-select>
				</a-form-item>
				<a-form-item label="装饰描述">
					<a-textarea v-model:value="decorateForm.content" placeholder="装饰描述" :rows="4" />
				</a-form-item>
				<a-form-item label="上架">
					<kd-switch :value="decorateForm.is_put_away" @change="e=>decorateForm.is_put_away = e" :active-value="1" :inactive-value="0"></kd-switch>
				</a-form-item>
			</a-form>
		</a-modal>
	</div>
</template>

<script>
import { reactive, toRefs } from 'vue'
import landCustom from '@/api/addons/landCustom.js'
import common from '@/api/common.js'
export default{
	setup(){
		const _d = reactive({
			active:'1',
			info:{
				list:[],
				page:1,
				limit:10,
				count:0
			},
			type:[],
			decorateForm:null,
			form:null,
			show:{
				edit:false,
				decorate:false,
			},
		})	
		getDecorateList(1,_d.info.limit)
		getDecorateType()
		function getDecorateList(page,limit){
			landCustom.getDecorate(page,limit,res=>_d.info = {limit,...res})
		}
		
		function getDecorateType(){
			landCustom.getDecorateType(1,999,res=>_d.type = res.list)
		}

		function showDecorateEdit(row){
			_d.decorateForm = {
				id:row ? row.id :0,
				cate:row ? parseInt(row.cate) :"",
				name:row ? row.name :"",
				spec:row ? row.spec :"",
				price:row ? row.price :"",
				images:row ? row.images :"",
				content:row ? row.content :"",
				is_put_away:row ? row.is_put_away :0,
			}
			_d.show.decorate = true
		}

		function showTypeEdit(row){
			_d.form ={
				id:row ? row.id :0,
				name:row ? row.name:'',
				rank:row ? row.rank:99
			}
			_d.show.edit = true
		}

		const saveDecorate = ()=> landCustom.addOrEditDecorate(_d.decorateForm,()=>{
			getDecorateList(_d.info.page,_d.info.limit)
			_d.show.decorate = false
		})

		const saveDecorateType = () => landCustom.addOrEditDecorateType(_d.form,()=>{
			getDecorateType()
			_d.show.edit = false
		})

		const handleDelete = (id,type,title)=>(
			common.deleteDataList(id,type,title).then(()=>{
				type == 57 ? getDecorateList(_d.info.page,_d.info.limit) :getDecorateType()
			})
		)
		return {
			...toRefs(_d),
			showDecorateEdit,
			getDecorateList,
			saveDecorate,
			handleDelete,
			showTypeEdit,
			saveDecorateType
		}
	}
}
</script>
<style lang="scss">
</style>
