import { $post } from '@/api/http.js'
import tool from '@/util/tool.js'
import common from "@/api/common.js"

class LandCustom{
    /**
     * 获取装饰列标
     * @param {number} page 
     * @param {number} limit 
     * @param {function} fn 
     */
    getDecorate(page,limit,fn){
        let param = {page,limit}
        common.getDataList("getSolutionList",param).then(res=>{
            fn(res)
        })
    }

    /**
     * 添加或编辑装饰信息
     * @param {object} param 
     * @param {function} fn 
     * @returns 
     */
    addOrEditDecorate(param,fn){
        if( !tool.returnMessage(param.cate,'请选择分类')) return
        if( !tool.returnMessage(param.name,'请填写名称')) return
        if( !tool.returnMessage(param.price,'请填写价格')) return
        if( !tool.returnMessage(param.price,'请填写规格')) return
        if( !tool.returnMessage(param.images,'请选择图片')) return
        if( !param.id) delete param.id
        $post("setOrEditSolution",param).then(res=>{
            tool.message("保存成功")
            fn(res.data)
        })
    }

    /**
     * 获取装饰分类信息
     * @param {number} page 
     * @param {number} limit 
     * @param {function} fn 
     */
    getDecorateType(page,limit,fn){
        let param = {page,limit}
        common.getDataList("getCsCateList",param).then(res=>{
            fn(res)
        })
    }

    /**
     * 添加装饰分类
     * @param {object} param 
     * @param {function} fn 
     */
    addOrEditDecorateType(param,fn){
        if( !tool.returnMessage(param.name,'请输入分类名称')) return
        if( !param.id) delete param.id
        $post("addOrEditCSCate",param).then(res=>{
            tool.message("保存成功")
            fn(res.data)
        })
    }

    /**
     * 获取土地工单信息
     * @param {number} page 
     * @param {number} limit 
     * @param {object} extra 
     * @param {function} fn 
     */
    getCusomLandWorkOrder(page,limit,extra,fn){
        let param = {page,limit}
        if( extra.order_number ) param.order_number = extra.order_number
        if( extra.mobile ) param.mobile = extra.mobile
        if( extra.order_status ) param.order_status = extra.order_status
        common.getDataList("landWorkOrderListBy2D",param).then(res=>{
            fn(res)
        })
    }

    /**
     * 指派工单
     * @param {number} user_id 工人userid
     * @param {number} id       工单id
     * @param {function} fn 
     * @returns 
     */
     setCustomWorkOrderWorker(user_id,id,fn){
        if( !tool.returnMessage(user_id,'请选择工人')) return
        $post("workOrderAssignmentBy2D",{user_id,id}).then(res=>{
            tool.message("设置成功")
            fn(res)
        })
    }

    /**
     * 获取操作设置
     * @param {function} fn 
     */
    getCustomWorkNav(fn){
        $post("getworkNaviSetList").then(res=>{
            let data = res.data.con
            data.forEach(item=>{
                if( item.type == 7 && item.chil.length == 0){
                    item.chil.push({
                        title:'采摘',
                        pirce:"",
                        two_price:"",
                        company:1,
                        two_company:1,
                        special_type:7
                    })
                }
            })

            fn(data)
        })
    }

    /**
     * 保存操作详情设置
     * @param {object} param 
     * @param {function} fn 
     */
    addOrEditWorkDetail(param,fn){
        $post('addOrEditWorkerDetails',{data:param}).then(res=>{
            tool.message("保存成功")
            fn(res.data)
        })
    }

    /**
     * 保存操作设置
     * @param {object} param 
     */
    addOrEditWorkNav(param){
        $post('setworkNavi',{data:param}).then(()=>{})
    }

    /**
     * 删除操作详情
     * @param {number} id 
     * @param {function} fn 
     */
    deleteWorkDetail(id,fn){
        $post("deleteWorkerDetails",{id}).then(res=>{
            tool.message("删除成功")
            fn(res.data)
        })
    }
}

const landCustomModel = new LandCustom()
export default landCustomModel